import React from 'react'
import SectionContainer from './SectionContainer'

const SectionDatenschutz = () => (
  <SectionContainer className="article">
    <h1 className="title">
      Datenschutzerklärung
      <p className="version">Version vom 10. Oktober 2021</p>
    </h1>

    <h2 className="paragraph">Datenschutz</h2>
    <p>
      Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst. Alle personenbezogenen
      Informationen werden vertraulich und gemäß den gesetzlichen Vorschriften behandelt, wie in dieser
      Datenschutzerklärung erläutert.
    </p>
    <p>
      Unserer Webseite kann selbstverständlich genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu
      irgendeinem Zeitpunkt persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden, wird dies auf
      freiwilliger Basis geschehen. Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an Dritte
      weitergegeben.
    </p>
    <p>
      Wir weisen darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser
      Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>

    <h2 className="paragraph">Benennung der verantwortlichen Stelle</h2>
    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
    <p>
      Helena Gläßer <br />
      Johannisweg 6 <br />
      85307 Paunzhausen <br />
      helena@kinderbuch.party
    </p>
    <p>
      Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der
      Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
    </p>

    <h2 className="paragraph">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
    <p>
      Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer
      bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail.
      Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>

    <h2 className="paragraph">Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h2>
    <p>
      Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der
      zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der
      Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende
      Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{' '}
      <a
        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
        rel="noreferrer"
        target="_blank"
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>

    <h2 className="paragraph">Recht auf Datenübertragbarkeit</h2>
    <p>
      Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
      automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem
      maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
      verlangen, erfolgt dies nur, soweit es technisch machbar ist.
    </p>

    <h2 className="paragraph">Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h2>
    <p>
      Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über
      Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch
      zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten
      Kontaktmöglichkeiten an uns wenden.
    </p>

    <h2 className="paragraph">SSL- bzw. TLS-Verschlüsselung</h2>
    <p>
      Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber
      senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website
      übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
      Ihres Browsers und am Schloss-Symbol in der Browserzeile.
    </p>

    <h2 className="paragraph">Server-Log-Dateien</h2>
    <p>
      In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser
      automatisch an uns übermittelt. Dies sind:
    </p>
    <ul>
      <li>Besuchte Seite auf unserer Domain</li>
      <li>Datum und Uhrzeit der Serveranfrage</li>
      <li>Browsertyp und Browserversion</li>
      <li>Verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>IP-Adresse</li>
    </ul>
    <p>
      Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung
      bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
      vorvertraglicher Maßnahmen gestattet.
    </p>

    <h2>Kontaktformular</h2>
    <p>
      Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage
      bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre
      Einwilligung nicht statt.
    </p>
    <p>
      Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer
      Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit
      möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
      erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
    </p>
    <p>
      Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
      Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende
      gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.
    </p>

    <h2 className="paragraph">Newsletter-Daten</h2>
    <p>
      Zum Versenden unseres Newsletters benötigen wir von Ihnen eine E-Mail-Adresse. Eine Verifizierung der angegebenen
      E-Mail-Adresse ist notwendig und der Empfang des Newsletters ist einzuwilligen. Ergänzende Daten werden nicht
      erhoben oder sind freiwillig. Die Verwendung der Daten erfolgt ausschließlich für den Versand des Newsletters.
    </p>
    <p>
      Die bei der Newsletteranmeldung gemachten Daten werden ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6
      Abs. 1 lit. a DSGVO) verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den
      Widerruf genügt eine formlose Mitteilung per E-Mail oder Sie melden sich über den "Austragen"-Link im Newsletter
      ab. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
    </p>
    <p>
      Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung gelöscht. Sollten diese Daten für
      andere Zwecke und an anderer Stelle an uns übermittelt worden sein, verbleiben diese weiterhin bei uns.
    </p>
    <div className="divider"></div>
  </SectionContainer>
)

export default SectionDatenschutz
