import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import SectionDatenschutz from '../components/SectionDatenschutz'
import Seo from '../components/seo'

import '../assets/scss/styles.scss'

function DatenschutzPage() {
  return (
    <Layout>
      <Seo title="Helena's Kinderbuchparty - Datenschutzerklärung" />
      <Navbar logoVisible={false} />
      <SectionDatenschutz />
    </Layout>
  )
}

export default DatenschutzPage
